import round from 'lodash/round';
import { getMetricsUnits } from '@constants/charts';
import { Action, ActionCategoryType, ActionContent, ActionEvidence } from 'models/action';
import { DateRange, Metric } from 'models/charts';
import { CustomColors } from 'styles/theme';
import { getConvertedValue } from './generatedData';
import { formatTime } from './formatTimer';
import { UserRole } from 'models/user';
import { ACTION_EVIDENCE_METRICS } from '@constants/actions';
import { getUnixTime, subHours } from 'date-fns';
import { getMetricArray, getSmaMetrics } from './chartTransform';

export const getActionColor = (actionCategory: ActionCategoryType): CustomColors => {
  switch (actionCategory) {
    case 'HF_BIOMETRICS':
    case 'BIOMETRICS':
      return 'red';
    default:
      return 'cyan';
  }
};

export const getTriggeredValue = (
  content: ActionContent | undefined,
  evidence: ActionEvidence[] | undefined,
  withMetric: boolean = false
): string => {
  const primaryMetric =
    evidence?.find((item) => item.order.toLowerCase() === 'primary')?.metric || '';
  const metric = ACTION_EVIDENCE_METRICS[primaryMetric] || '';
  const metricsUnits = getMetricsUnits();

  if (metric === 'band_removed') {
    const triggeredKey = Object.keys(content?.triggered_values || {}).find((key) =>
      key.includes('band')
    );

    if (content?.triggered_values && triggeredKey) {
      const value = content?.triggered_values[triggeredKey];

      return formatTime(value);
    }

    return '';
  }

  const triggeredKey = Object.keys(content?.triggered_values || {}).find((key) =>
    key.includes(metric)
  );

  if (content?.triggered_values && triggeredKey) {
    const value = content?.triggered_values[triggeredKey];
    const convertedValue =
      metric === 'weight' ||
      metric === 'heart_rate' ||
      metric === 'spo2' ||
      metric === 'resp_rate' ||
      metric === 'rmssd' ||
      metric === 'rhr'
        ? metric === 'weight'
          ? getRoundedValue(metric, value)
          : getRoundedValue(metric, value, false)
        : getConvertedValue(value, metric);

    return withMetric
      ? `${convertedValue}${metricsUnits[metric] || ''}`
      : `${convertedValue}${metric === 'spo2' ? metricsUnits.spo2 : ''}`;
  }

  return '';
};

export const getRoundedValue = (metric: Metric, value: number, rounding: boolean = true) =>
  value ? round(getConvertedValue(value, metric), rounding ? 2 : 0) : '';

export const getUserRole = (roles?: UserRole[]) =>
  roles?.find((role: UserRole) => role !== 'clinician' && role !== 'tenant_admin') || 'md';

export const getEvidencePeriod = (evidence: ActionEvidence) => {
  const timeAvg = evidence.timeframe_average || '24h';
  const regex = /(\d+)([hdwmy])/;
  const matches = timeAvg.match(regex);

  if (matches && matches[1] && matches[2]) {
    const value = parseInt(matches[1], 10);
    const unit = matches[2].toLowerCase();

    let hours;
    switch (unit) {
      case 'h':
        hours = value;
        break;
      case 'd':
        hours = value * 24;
        break;
      case 'w':
        hours = value * 24 * 7;
        break;
      case 'm':
        hours = value * 24 * 30;
        break;
      case 'y':
        hours = value * 24 * 365;
        break;
      default:
        hours = 24;
    }
    const days = Math.ceil(hours / 24);
    const chartDays = days >= 7 ? 7 : days === 1 ? 1 : 2;

    return {
      hours,
      days: chartDays,
      unit,
      period: matches[0],
    };
  }

  return {
    hours: 24,
    days: 1,
    unit: 'h',
    period: '24h',
  };
};

export const getEvidenceData = ({ evidence }: Action, createdDate: Date) =>
  evidence?.map((item) => {
    const metric = ACTION_EVIDENCE_METRICS[item.metric];
    const order = item.order;
    const key = `${metric}-${order}`;
    let evidencePeriod;
    let timeAvg;
    let timeBaseline;
    if (order.toLowerCase() === 'secondary') {
      const primaryItem = evidence?.find((item) => item.order.toLowerCase() === 'primary');
      evidencePeriod = getEvidencePeriod(primaryItem ?? item);
      timeAvg = primaryItem?.timeframe_average || item?.timeframe_average || '24h';
      timeBaseline = primaryItem?.timeframe_baseline || item?.timeframe_baseline || '7d';
    } else {
      evidencePeriod = getEvidencePeriod(item);
      timeAvg = item?.timeframe_average || '24h';
      timeBaseline = item?.timeframe_baseline || '7d';
    }
    const startTime = getUnixTime(subHours(createdDate, evidencePeriod.hours));
    const metricsArray = getMetricArray(metric, evidencePeriod.days as DateRange);
    const smaMetrics = getSmaMetrics(metricsArray);

    return {
      metric,
      metricsArray,
      order,
      key,
      startTime,
      timeAvg,
      timeBaseline,
      smaMetrics,
      ...evidencePeriod,
    };
  }) || [];
