import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { AxiosRequestConfig } from 'axios';
import { RadiusApiError } from '../../base';
import { EngagementReportRawData, PeriodItem, ReportResponse } from '../../../models/reports';
import { queryParamsReportsByPeriod } from '../../../utils/reports';
import { TOTAL_PAGES } from '@constants/reportBilling';
import { flatMap, range } from 'lodash';

export interface EngagementReportParams {
  period: PeriodItem;
  clinicianId?: string;
  patientIds?: string[];
}

type baseQueryType = (
  arg: AxiosRequestConfig
) => MaybePromise<QueryReturnValue<unknown, RadiusApiError, {}>>;

interface EngagementRequestData {
  queryParams: EngagementReportParams;
  baseQuery: baseQueryType;
}

const fetchEngagementData = async ({ queryParams, baseQuery }: EngagementRequestData) => {
  try {
    const engagementParams = queryParamsReportsByPeriod(queryParams.period);
    let engagementData: EngagementReportRawData[] = [];
    let isEngagementNext = true;
    let engagementPageNumberStart = 0;
    let engagementPageNumberEnd = TOTAL_PAGES;

    if (queryParams.patientIds?.length) {
      const patientIds = queryParams.patientIds.join(',');
      engagementParams.append('patientIds', patientIds);
    }

    if (queryParams?.clinicianId) {
      engagementParams.append('careMemberId', queryParams?.clinicianId);
    }

    while (isEngagementNext) {
      const pages = range(engagementPageNumberStart, engagementPageNumberEnd);

      const reportsResponse = await Promise.all(
        pages.map(async (pageNumber) =>
          baseQuery({
            url: `/datalake-service/v1/reports/engagement-app/${
              queryParams.period.period
            }?pageNumber=${pageNumber}&${engagementParams.toString()}`,
            method: 'GET',
          })
        )
      );

      if (reportsResponse[0].error) {
        return { error: reportsResponse[0].error };
      }

      const reportsResponseData =
        reportsResponse?.flatMap((obj: any) => obj?.data || []) || ([] as ReportResponse[]);
      const reportList = flatMap(
        reportsResponseData.map(
          (report) =>
            report?._embedded?.engagementAppDailyReportList ||
            report?._embedded?.engagementAppMonthlyReportList ||
            report?._embedded?.engagementAppWeeklyReportList ||
            []
        )
      );
      isEngagementNext = !!reportsResponseData[reportsResponseData.length - 1]?._links?.next;
      engagementData = [...engagementData, ...reportList];
      engagementPageNumberStart = engagementPageNumberEnd;
      engagementPageNumberEnd = engagementPageNumberEnd + TOTAL_PAGES;
    }

    return { data: engagementData };
  } catch (error) {
    console.error(error);
  }
};

export default fetchEngagementData;
