import { differenceInDays, format, isThisMonth, isWithinInterval } from 'date-fns';
import {
  BillingCareMemberRawData,
  EngagementCareMemberRawData,
  EngagementReportRawData,
  ExternalIdColumn,
  Period,
  PeriodItem,
} from 'models/reports';
import { formatSeconds } from './reports';
import { flatMap, groupBy, map, orderBy, sumBy } from 'lodash';
import { User } from 'models/user';
import { USER_ROLE } from '@constants/team';
import { getUserRole } from './action';
import { getReportMemberStatus } from './memberStatus';

interface ReportFromBillingCareMemberData {
  billingData: BillingCareMemberRawData[];
  engagementKeys: { [key: string]: EngagementReportRawData };
  mrnData: Record<string, string>;
  period: PeriodItem;
  clinicianKeys: { [key: string]: User };
  memberSinceDates: Record<string, string>;
}

interface ReportFromBillingCareMemberDataReturn {
  billingData: BillingCareMemberRawData[];
  billingByPatient: BillingCareMemberRawData[];
  engagementSummaryData: EngagementCareMemberRawData;
  careTeamSummaryData: Partial<BillingCareMemberRawData>[];
}

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
  period?: Period;
}

export const reportFromBillingCareMemberData = ({
  billingData,
  engagementKeys,
  mrnData,
  period,
  clinicianKeys,
  memberSinceDates,
}: ReportFromBillingCareMemberData): ReportFromBillingCareMemberDataReturn => {
  let totalCareTeamSeconds = 0;
  let careTeam: { [key: string]: Partial<BillingCareMemberRawData> } = {};

  const newBillingData = orderBy(
    billingData
      .filter((item) => item.isInTheTenant !== null)
      .map((item: BillingCareMemberRawData) => {
        const lastDate = isThisMonth(period?.value.end) ? new Date() : period?.value.end;
        const memberSinceTime = memberSinceDates[item.mrn || ''] ?? undefined;
        const memberSinceDate = memberSinceTime
          ? format(new Date(memberSinceTime), 'MM/dd/yyyy')
          : undefined;
        const memberSinceDays = memberSinceTime
          ? differenceInDays(lastDate, new Date(memberSinceTime))
          : null;
        const billingOnboardedDate = item.memberOnboardedTimestamp
          ? format(new Date(item.memberOnboardedTimestamp), 'MM/dd/yyyy')
          : null;
        const billingDaysSinceEnrolled = item.memberSecondsEnrolled
          ? Math.ceil(item.memberSecondsEnrolled / 86400)
          : null;
        const memberOnboardedDate = billingOnboardedDate ?? memberSinceDate;
        const memberDaysSinceEnrolled = billingDaysSinceEnrolled ?? memberSinceDays;
        const memberOnboardedTimestamp = item.memberOnboardedTimestamp ?? memberSinceTime;
        const memberCreatedDate = item.memberCreatedAt
          ? format(new Date(item.memberCreatedAt), 'MM/dd/yyyy')
          : null;
        const totalDaysBandSynced = engagementKeys?.[item.mrn || '']?.totalDaysBandSynced || 0;
        const criteriaMet =
          period?.period === 'monthly' ? (totalDaysBandSynced >= 16 ? 'Yes' : 'No') : null;
        const careMemberTotalTime = item?.careMemberTotalSecondsSpent
          ? formatSeconds(item?.careMemberTotalSecondsSpent)
          : null;
        const careTeamTotalTime = item?.careTeamTotalSecondsSpent
          ? formatSeconds(item?.careTeamTotalSecondsSpent)
          : null;
        const clinicianLastName =
          item.careMemberId && clinicianKeys && clinicianKeys[item?.careMemberId]
            ? clinicianKeys[item?.careMemberId].personalInfo.lastName
            : null;
        const clinicianFirstName =
          item.careMemberId && clinicianKeys && clinicianKeys[item?.careMemberId]
            ? clinicianKeys[item?.careMemberId].personalInfo.firstName
            : null;
        const clinicianMiddleName =
          item.careMemberId && clinicianKeys && clinicianKeys[item?.careMemberId]
            ? clinicianKeys[item?.careMemberId].personalInfo.middleName
            : null;
        const clinicianRoles =
          item.careMemberId && clinicianKeys && clinicianKeys[item?.careMemberId]
            ? clinicianKeys[item.careMemberId].roles
            : null;
        const clinicianRole =
          item.careMemberId && clinicianKeys && clinicianKeys[item?.careMemberId]
            ? USER_ROLE[getUserRole(clinicianKeys[item.careMemberId].roles)]
            : null;
        const careMemberRole = item.careMemberRole
          ? USER_ROLE[getUserRole(item.careMemberRole)]
          : null;

        const careMemberData = {
          careMemberId: item.careMemberId,
          careMemberFamilyName: item.careMemberFamilyName ?? clinicianLastName,
          careMemberMiddleName: item.careMemberMiddleName ?? clinicianMiddleName,
          careMemberGivenName: item.careMemberGivenName ?? clinicianFirstName,
          careMemberRole: item.careMemberRole || clinicianRoles,
          careMemberAssignedRole: careMemberRole || clinicianRole,
          qhcp: item.qhcp,
          careTeamTotalSecondsSpent: item.careTeamTotalSecondsSpent,
          careMemberTotalTime,
        };

        const newReport = {
          ...item,
          uid: item.mrn,
          externalMrn: item?.externalMrn ?? mrnData ? mrnData[item.mrn || ''] : null,
          memberOnboardedTimestamp,
          memberOnboardedDate,
          memberCreatedDate,
          memberDaysSinceEnrolled,
          careMemberTotalTime,
          careTeamTotalTime,
          totalDaysBandSynced,
          criteriaMet,
          memberStatus: getReportMemberStatus({ ...item, memberOnboardedTimestamp }),
          isCreated:
            memberCreatedDate &&
            period &&
            isWithinInterval(new Date(memberCreatedDate), period.value),
          isOnboarded:
            memberOnboardedDate &&
            period &&
            isWithinInterval(new Date(memberOnboardedDate), period.value),
          ctmUsers: item.careMemberId ? [careMemberData] : null,
          ...(item?.careMemberId && { ...careMemberData }),
        } as BillingCareMemberRawData;

        if (newReport.careMemberId && newReport?.careTeamTotalSecondsSpent) {
          const careMemberTotalSecondsSpent =
            (careTeam[newReport.careMemberId]?.careMemberTotalSecondsSpent || 0) +
            (newReport?.careMemberTotalSecondsSpent || 0);
          careTeam = {
            ...careTeam,
            [newReport.careMemberId]: {
              ...careTeam[newReport.careMemberId],
              ...careMemberData,
              careMemberTotalSecondsSpent,
              careMemberTotalTime: careMemberTotalSecondsSpent
                ? formatSeconds(careMemberTotalSecondsSpent)
                : null,
            },
          };
        }

        return newReport;
      }),
    ['uid', 'memberGivenName']
  );

  const billingByPatient = orderBy(
    map(groupBy(newBillingData, 'uid'), (objs) => {
      const cateTeamTotalSeconds = sumBy(objs, 'careMemberTotalSecondsSpent');
      totalCareTeamSeconds = totalCareTeamSeconds + (cateTeamTotalSeconds || 0);
      return {
        ...objs[0],
        cateTeamTotalSeconds,
        careTeamTotalTime: cateTeamTotalSeconds ? formatSeconds(cateTeamTotalSeconds) : null,
        ctmUsers: flatMap(
          objs.filter((o) => o?.ctmUsers && o.ctmUsers !== null).map((o) => o.ctmUsers)
        ),
      };
    }) as BillingCareMemberRawData[],
    [(report) => report.memberGivenName?.toLowerCase().replace(/[^\w]/gi, '')]
  );

  const engagementSummaryData = {
    usersInThisReport: billingByPatient.length,
    usersOnboarded: sumBy(billingByPatient, (report) => (report.memberOnboardedDate ? 1 : 0)),
    usersActive: sumBy(billingByPatient, (report) => (report.memberStatus === 'ACTIVE' ? 1 : 0)),
    usersDischarged: sumBy(billingByPatient, (report) =>
      report.memberStatus === 'DISCHARGED' ? 1 : 0
    ),
    usersOnHold: sumBy(billingByPatient, (report) => (report.memberStatus === 'HOLDING' ? 1 : 0)),
    usersCriteriaMet: sumBy(billingByPatient, (report) => (report.criteriaMet === 'Yes' ? 1 : 0)),
    usersRemoved: sumBy(billingByPatient, (report) => (report.isInTheTenant === null ? 1 : 0)),
    totalCareTeamTime: formatSeconds(totalCareTeamSeconds),
  };

  return {
    billingData: newBillingData,
    billingByPatient,
    engagementSummaryData,
    careTeamSummaryData: orderBy(Object.values(careTeam), [
      (care) => care.careMemberGivenName?.toLowerCase().replace(/[^\w]/gi, ''),
    ]),
  };
};

export const getCsvHeaders = ({ externalIdColumns, period }: CsvHeaderProps) => {
  return [
    ...(period === 'monthly'
      ? [{ label: 'Reporting Month', key: 'month' }]
      : [{ label: 'Reporting Week', key: 'week' }]),
    { label: 'Reporting Year', key: 'year' },
    { label: 'First Name', key: 'memberGivenName' },
    { label: 'Last Name', key: 'memberFamilyName' },
    { label: 'User ID', key: 'uid' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'memberStatus' },
    { label: 'Account Created', key: 'memberCreatedDate' },
    { label: 'Member Since', key: 'memberOnboardedDate' },
    { label: 'Account Age', key: 'memberDaysSinceEnrolled' },
    { label: 'Band Compliance', key: 'totalDaysBandSynced' },
    ...(period === 'monthly' ? [{ label: '16d Req', key: 'criteriaMet' }] : []),
    { label: 'CTM First Name', key: 'careMemberGivenName' },
    { label: 'CTM Last Name', key: 'careMemberFamilyName' },
    { label: 'CTM ID', key: 'careMemberId' },
    { label: 'CTM Role', key: 'careMemberAssignedRole' },
    { label: 'QHCP', key: 'qhcp' },
    {
      label: 'CTM Time',
      key: 'careMemberTotalTime',
    },
    {
      label: 'Total Time',
      key: 'careTeamTotalTime',
    },
  ];
};
