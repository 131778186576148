import React from 'react';
import { Box } from '@mui/material';
import Footer from 'components/Footer';
import TenantSelector from './components/TenantSelector';
import useStyles from './styles';

const Auth = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TenantSelector />
      <Box className={classes.footerContainer}>
        <Box className={classes.footer}>
          <Footer isWhiteBackground />
        </Box>
      </Box>
    </Box>
  );
};

export default Auth;
