import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { AxiosRequestConfig } from 'axios';
import { RadiusApiError } from '../../base';
import { chunk } from 'lodash';

type baseQueryType = (
  arg: AxiosRequestConfig
) => MaybePromise<QueryReturnValue<unknown, RadiusApiError, {}>>;

const fetchMrn = async ({
  patientIdentifiers,
  baseQuery,
}: {
  patientIdentifiers: string[];
  baseQuery: baseQueryType;
}) => {
  const patientChunkIdentifiers = chunk(patientIdentifiers, 50);

  const fhirChunkResponse = await Promise.all(
    patientChunkIdentifiers.map(async (ids) =>
      baseQuery({
        url: `/fhirservice/api/v1.0/patients?_elements=identifier&identifier=${ids}`,
        method: 'GET',
      })
    )
  );

  if (fhirChunkResponse?.length && fhirChunkResponse[0]?.error) {
    return {};
  }

  const fhirData = fhirChunkResponse?.flatMap((obj: any) => obj?.data || []) || [];
  const mrnData: Record<string, string> = {};
  fhirData?.forEach((fhir) => {
    const uid = fhir?.identifier?.find((item: any) =>
      item?.system?.includes('urn:all.health')
    )?.value;
    const mrn =
      fhir?.identifier?.find((item: any) => item?.system?.includes('urn:epic'))?.value || null;
    mrnData[uid] = mrn;
  });

  return mrnData;
};

export default fetchMrn;
