import {
  differenceInMilliseconds,
  endOfDay,
  format,
  isBefore,
  minutesToMilliseconds,
  startOfDay,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { addCalendarEventToNotificationsList } from 'store/notifications';
import { calendarApi } from 'services/calendar';
import { useAppDispatch, useAppSelector } from './../store/index';
import { tokenParsed } from 'store/auth';

const REMINDER = 'reminder';

const useCalendarNotifications = () => {
  const [calendarEventTimeout, setCalendarEventTimeout] = useState<ReturnType<typeof setTimeout>[]>(
    []
  );
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const dispatch = useAppDispatch();
  const [fetchCalendarEventsForNotifications, { data: events, isSuccess }] =
    calendarApi.endpoints.fetchEventsInInterval.useLazyQuery();

  const interval = useMemo(() => {
    const currentDate = new Date();
    return {
      start: startOfDay(currentDate),
      end: endOfDay(currentDate),
    };
  }, []);

  const handleFetchCalendarEventsForNotifications = useCallback(() => {
    if (interval && tokenParsedSelector?.sub) {
      fetchCalendarEventsForNotifications({
        userId: tokenParsedSelector?.sub || 'unknown',
        interval,
      });
    }
  }, [fetchCalendarEventsForNotifications, interval, tokenParsedSelector?.sub]);

  const clearTimeOuts = useCallback(() => {
    calendarEventTimeout.forEach((calendarEvent) => {
      return () => clearTimeout(calendarEvent);
    });
    if (calendarEventTimeout.length > 0) {
      setCalendarEventTimeout([]);
    }
  }, [calendarEventTimeout]);

  const handleCalendarNotifications = useCallback(() => {
    if (events) {
      if (calendarEventTimeout.length >= 0) {
        clearTimeOuts();
      }
      const newTimeOutsForCalendarEvents = calendarEventTimeout;
      events.forEach((event) => {
        let isBeforeNow: Boolean = false;
        let timeDifferenceInMilliseconds = 0;
        const now = new Date();
        const eventStartTime = event.start;
        isBeforeNow = isBefore(eventStartTime, now);
        timeDifferenceInMilliseconds = differenceInMilliseconds(eventStartTime, now);
        if (!isBeforeNow || event.type === REMINDER) {
          const newTimeOut = setTimeout(
            () => {
              const newNotification = {
                id: event.id,
                title: event.title,
                time:
                  event.type === REMINDER
                    ? null
                    : `${format(event.start, 'hh:mmaaa')} - ${format(event.end, 'hh:mmaaa')}`,
                clicked: false,
                startsAt: new Date(event.start).toISOString(),
              };
              dispatch(addCalendarEventToNotificationsList(newNotification));
              // TODO: CHANGE FOR "event.notifyBefore" WHEN THE BE HAS THIS ATTRIBUTE WORKING
            },
            event.type === REMINDER ? 0 : timeDifferenceInMilliseconds - minutesToMilliseconds(5)
          );

          newTimeOutsForCalendarEvents.push(newTimeOut);
          setCalendarEventTimeout(newTimeOutsForCalendarEvents);
        }
      });
    }
  }, [calendarEventTimeout, clearTimeOuts, dispatch, events]);

  useEffect(() => {
    if (isSuccess) {
      handleCalendarNotifications();
    }
  }, [isSuccess, handleCalendarNotifications]);

  useEffect(() => {
    clearTimeOuts();
  }, [clearTimeOuts]);

  return {
    handleFetchCalendarEventsForNotifications,
  };
};

export default useCalendarNotifications;
