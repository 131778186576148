import Keycloak, { KeycloakInitOptions } from 'keycloak-js';

import { getHost } from 'utils/host';
import { loadState } from 'utils/localStorage';

const keycloakCareConfig = {
  url: `https://keycloak.${getHost()}/`,
  realm: 'care',
  clientId: 'react-app',
};

const keycloakPatientConfig = {
  url: `https://keycloak.${getHost()}/`,
  realm: 'patient',
  clientId: 'patient-portal-phone', // TODO: patient-portal-email
};

export const tokenMinValiditySeconds = 36000;

export const keycloakCare: Keycloak = new Keycloak(keycloakCareConfig);
export const keycloakPatient: Keycloak = new Keycloak(keycloakPatientConfig);

export const getKeycloakInitOptions = () => {
  const token = loadState('token', 'STRING');
  const idToken = loadState('idToken', 'STRING');
  const refreshToken = loadState('refreshToken', 'STRING');

  const keycloakInitOptions: KeycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: true,
    token: token,
    idToken: idToken,
    refreshToken: refreshToken,
  };

  return keycloakInitOptions;
};

export const tokenLogger = (tokens: {
  token?: string;
  idToken?: string;
  refreshToken?: string;
}) => {
  tokens.token && localStorage.setItem('token', tokens.token);
  tokens.refreshToken && localStorage.setItem('refreshToken', tokens.refreshToken);
  tokens.idToken && localStorage.setItem('idToken', tokens.idToken);
};

export const clearCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};

export const clearSession = () => {
  const notifications = localStorage.getItem('notifications');
  clearCookies();
  sessionStorage.clear();
  localStorage.clear();
  if (notifications) {
    localStorage.setItem('notifications', notifications);
  }
};

export const keycloak = window.location.host.includes('patient.') ? keycloakPatient : keycloakCare;
