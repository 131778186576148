export const getHost = () => {
  let host = window.location.host;
  if (host.includes('localhost')) {
    host = 'radius-dev.all.health';
  }
  if (host.includes('patient.')) {
    host = host.replace('patient.', '');
  }
  if (host.includes('admin.')) {
    host = host.replace('admin.', '');
  }
  return host;
};

export const getBaseUrl = () => {
  let host = window.location.origin;

  if (host.includes('patient.')) {
    return host.replace('patient.', '');
  }
  if (host.includes('admin.')) {
    return host.replace('admin.', '');
  }

  return host;
};
