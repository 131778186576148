import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import type { RootState } from 'store/rootReducer';
import { keycloak } from '../keycloak';
import { getBaseUrl } from 'utils/host';

export interface RadiusApiError {
  status?: number;
  data?: unknown;
}

const axiosBaseQuery =
  (
    { baseURL }: { baseURL: string } = {
      baseURL: '',
    }
  ): AxiosBaseQueryFn =>
  async (requestOpts, queryApi) => {
    const tenant = (queryApi.getState() as RootState).auth.tenant;
    const host = getBaseUrl();

    const config = {
      ...requestOpts,
      baseURL: host + baseURL,
      headers: {
        Authorization: keycloak.authenticated ? `Bearer ${keycloak.token}` : undefined,
        ...requestOpts.headers,
        tenantId: tenant?.tenant_ID,
        tenantCode: tenant?.tenant,
      },
    };

    try {
      const result = await axios({ ...config });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (Number(err?.response?.status) === 401) {
        keycloak.login();
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiBaseQuery = (apiPath: string): AxiosBaseQueryFn =>
  axiosBaseQuery({ baseURL: apiPath });

export type AxiosBaseQueryFn = BaseQueryFn<AxiosRequestConfig, unknown, RadiusApiError, {}, {}>;
