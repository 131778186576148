import { differenceInDays, format, isThisMonth, isWithinInterval } from 'date-fns';
import {
  BandStatusReportRawData,
  BillingCareMemberRawData,
  EngagementCareMemberRawData,
  EngagementReportRawData,
  ExternalIdColumn,
  Period,
  PeriodItem,
} from 'models/reports';
import { Theme } from '@mui/material';
import {
  DatasetComponentOption,
  EChartsOption,
  YAXisComponentOption,
  CustomSeriesRenderItemParams,
  CustomSeriesRenderItemAPI,
  CustomSeriesRenderItemReturn,
} from 'echarts';
import { CoordSys } from 'models/charts';
import { orderBy, round, sumBy } from 'lodash';
import { getReportMemberStatus } from './memberStatus';
import { formatSeconds } from './reports';

interface ReportFromEngagementData {
  rawData: EngagementReportRawData[];
  period: PeriodItem;
  mrnData?: Record<string, string>;
  billingDataKeys?: Record<string, BillingCareMemberRawData>;
  memberSinceDates: Record<string, string>;
}

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
  period?: Period;
}

interface AccountSummary {
  bandData?: BandStatusReportRawData[];
  reports: EngagementReportRawData[];
  period: PeriodItem;
}

export const reportFromEngagementData = ({
  rawData,
  period,
  mrnData,
  billingDataKeys,
  memberSinceDates,
}: ReportFromEngagementData): EngagementReportRawData[] => {
  const totalPeriodDays = period ? differenceInDays(period?.value.end, period?.value.start) + 1 : 0;

  return orderBy(
    rawData
      .map((item: EngagementReportRawData) => {
        const lastDate = isThisMonth(period?.value.end) ? new Date() : period?.value.end;
        const memberSinceTime = memberSinceDates[item.uid || ''] ?? undefined;
        const memberSinceDate = memberSinceTime
          ? format(new Date(memberSinceTime), 'MM/dd/yyyy')
          : undefined;
        const memberSinceDays = memberSinceTime
          ? differenceInDays(lastDate, new Date(memberSinceTime))
          : null;

        const patientProfile =
          billingDataKeys && billingDataKeys[item.uid] ? billingDataKeys[item.uid] : null;
        const billingOnboardedTimestamp = patientProfile?.memberOnboardedTimestamp || null;
        const billingOnboardedDate = billingOnboardedTimestamp
          ? format(new Date(billingOnboardedTimestamp), 'MM/dd/yyyy')
          : null;
        const billingDaysSinceEnrolled = patientProfile?.memberSecondsEnrolled
          ? Math.ceil(patientProfile.memberSecondsEnrolled / 86400)
          : null;
        const isInTheTenant = patientProfile ? patientProfile.isInTheTenant : null;
        const isOnHold = patientProfile ? patientProfile.isOnHold : null;
        const mrn = mrnData && item.uid && mrnData[item.uid] ? mrnData[item.uid] : null;

        const memberOnboardedTimestamp = billingOnboardedTimestamp ?? memberSinceTime;
        const memberOnboardedDate = billingOnboardedDate ?? memberSinceDate;
        const memberDaysSinceEnrolled = billingDaysSinceEnrolled ?? memberSinceDays;

        return {
          ...item,
          mrn,
          bandWearingTime: item.bandWearingSeconds ? formatSeconds(item.bandWearingSeconds) : null,
          bandRemovedTime: item.bandRemovedSeconds ? formatSeconds(item.bandRemovedSeconds) : null,
          bandChargeTime: item.bandChargeSeconds ? formatSeconds(item.bandChargeSeconds) : null,
          numberVisitsAverage: item.numberVisits
            ? round(item.numberVisits / totalPeriodDays, 2)
            : null,
          isInTheTenant,
          isOnHold,
          memberCreatedAt: patientProfile?.memberCreatedAt || null,
          memberCreatedDate: patientProfile
            ? format(new Date(patientProfile.memberCreatedAt || 0), 'MM/dd/yyyy')
            : null,
          memberOnboardedDate,
          memberOnboardedTimestamp,
          memberDaysSinceEnrolled,
          memberStatus: getReportMemberStatus({
            ...item,
            isInTheTenant,
            isOnHold,
            memberOnboardedTimestamp,
          }),
          externalMrn: patientProfile?.externalMrn ?? mrn,
          externalEmployeeId: patientProfile?.externalEmployeeId || null,
          criteriaMet:
            period?.period === 'monthly'
              ? item?.totalDaysBandSynced && item.totalDaysBandSynced >= 16
                ? 'Yes'
                : 'No'
              : null,
          averageInteractions: item?.totalDaysBandSynced
            ? round(item?.totalDaysBandSynced / totalPeriodDays, 2)
            : null,
          device: 'Band01',
        };
      })
      .filter((report) => report.isInTheTenant !== null),
    [(report) => report.givenName?.toLowerCase().replace(/[^\w]/gi, '')]
  );
};

export const getEngagementChartOption = (chartData: any, theme: Theme): EChartsOption => {
  const axisColor = theme?.palette.text.primary || '#505661';
  const backgroundColor = theme?.palette.background.default || 'white';

  const global = {
    grid: [
      {
        id: 'grid_1',
        show: true,
        borderWidth: 0,
        top: 12,
        right: 45,
        left: 55,
        bottom: 24,
        height: '216px',
      },
      {
        id: 'grid_style_1',
        show: true,
        borderWidth: 0,
        top: 12,
        right: 0,
        left: 0,
        bottom: 24,
        height: '217px',
      },
    ],
    xAxis: [
      {
        id: 'xAxis_1',
        gridId: 'grid_1',
        type: 'category' as const,
        axisLabel: {
          color: '#000000',
          fontSize: 8,
          fontFamily: 'Gotham SSm',
          lineHeight: 18,
          margin: 6,
          showMaxLabel: true,
        },
        axisTick: {
          show: true,
          interval: 0,
          length: 5,
          lineStyle: {
            color: axisColor,
            opacity: 0.4,
          },
        },
        axisLine: {
          show: false,
        },
      },
      {
        id: 'xAxis_style_1',
        silent: true,
        gridId: 'grid_style_1',
        axisLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: axisColor,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        id: 'yAxis_1',
        gridId: 'grid_1',
        alignTicks: true,
        type: 'value',
        position: 'left',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          showMinLabel: false,
          showMaxLabel: true,
          color: '#000000',
          fontFamily: 'Gotham SSm',
          fontSize: 12,
          margin: 58,
          align: 'left',
          backgroundColor,
          padding: 4,
          opacity: 0.8,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: axisColor,
            width: 1,
            opacity: 0.08,
            type: 'dashed',
          },
        },
      },
      {
        id: `yAxis_2`,
        gridId: `grid_1`,
        alignTicks: true,
        type: 'value',
        position: 'right',
        silent: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          length: 45,
          lineStyle: {
            color: axisColor,
            width: 1,
            opacity: 0.08,
            type: 'dashed',
          },
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      {
        type: 'value',
        gridId: `grid_style_1`,
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ] as YAXisComponentOption[],
    dataset: {
      source: chartData,
    } as DatasetComponentOption,
  };

  return {
    ...global,
    series: [
      chartData.length > 1
        ? {
            name: 'accounts',
            type: 'line' as const,
            xAxisId: `xAxis_1`,
            yAxisId: `yAxis_1`,
            step: 'start',
            encode: {
              x: 'date',
              y: 'value',
            },
            showSymbol: false,
            symbol: 'circle',
            symbolSize: 6,
            connectNulls: true,
            emphasis: {
              disabled: true,
            },
            lineStyle: {
              color: '#00B9D1',
              width: 2,
            },
            itemStyle: {
              color: '#00B9D1',
              borderColor: backgroundColor,
              borderWidth: 2,
            },
            areaStyle: {
              color: '#00B9D1',
              opacity: 0.05,
            },
          }
        : {
            name: 'accounts',
            type: 'custom',
            xAxisId: `xAxis_1`,
            yAxisId: `yAxis_1`,
            encode: {
              x: 'date',
              y: 'value',
            },
            renderItem: function (
              params: CustomSeriesRenderItemParams,
              api: CustomSeriesRenderItemAPI
            ): CustomSeriesRenderItemReturn {
              const timestamp = api.value('date');
              const coords = api.coord([timestamp, api.value('value')]);
              const { x, width } = params.coordSys as CoordSys;
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    shape: {
                      x1: x,
                      x2: x + width,
                      y1: coords[1],
                      y2: coords[1],
                    },
                    transition: 'shape',
                    style: {
                      stroke: '#00B9D1',
                      lineWidth: 2,
                    },
                  },
                  {
                    type: 'circle',
                    shape: {
                      cx: coords[0],
                      cy: coords[1],
                      r: 4,
                    },
                    transition: 'shape',
                    style: {
                      fill: '#00B9D1',
                      stroke: backgroundColor,
                      lineWidth: 2,
                    },
                  },
                ],
              };
            },
          },
      {
        type: 'line',
        name: 'mock',
        silent: true,
        animation: false,
        sampling: 'lttb',
        xAxisId: `xAxis_1`,
        yAxisId: `yAxis_2`,
        showSymbol: false,
        symbol: 'none',
        lineStyle: {
          color: 'transparent',
        },
      },
    ],
  };
};

export const getCsvHeaders = ({ externalIdColumns, period }: CsvHeaderProps) => {
  return [
    ...(period === 'monthly'
      ? [{ label: 'Reporting Month', key: 'month' }]
      : [{ label: 'Reporting Week', key: 'week' }]),
    { label: 'Reporting Year', key: 'year' },
    { label: 'First Name', key: 'givenName' },
    { label: 'Last Name', key: 'familyName' },
    { label: 'User ID', key: 'uid' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'memberStatus' },
    { label: 'Account Created', key: 'memberCreatedDate' },
    { label: 'Member Since', key: 'memberOnboardedDate' },
    { label: 'Account Age', key: 'memberDaysSinceEnrolled' },
    ...(period === 'monthly' ? [{ label: '16d Req', key: 'criteriaMet' }] : []),
    { label: 'App Visits Daily Average', key: 'numberVisitsAverage' },
    { label: 'App Visits Total', key: 'numberVisits' },
  ];
};

export const getEngagementAccountSummary = ({
  reports,
  period,
  bandData,
}: AccountSummary): EngagementCareMemberRawData => {
  const usersInThisReport = reports.length || 0;
  const usersActive = sumBy(reports, (report) => (report.memberStatus === 'ACTIVE' ? 1 : 0));
  const usersDischarged = sumBy(reports, (report) =>
    report.memberStatus === 'DISCHARGED' ? 1 : 0
  );
  const usersOnHold = sumBy(reports, (report) => (report.memberStatus === 'HOLDING' ? 1 : 0));
  const usersOnboarded = sumBy(reports, (report) =>
    report.memberOnboardedDate &&
    period &&
    isWithinInterval(new Date(report.memberOnboardedDate), period?.value)
      ? 1
      : 0
  );
  const usersCreated = sumBy(reports, (report) =>
    report.memberCreatedDate &&
    period &&
    isWithinInterval(new Date(report.memberCreatedDate), period?.value)
      ? 1
      : 0
  );
  const usersCriteriaMet = sumBy(reports, (report) => (report.criteriaMet === 'Yes' ? 1 : 0));
  const bandsInSystem = sumBy(bandData, 'bandActiveSystem');
  const bandActivations = sumBy(bandData, 'numberBandsActivated');
  const replacementActivations = sumBy(bandData, 'bandReplacementActivations');
  const appEngagement = round(
    (reports.filter((obj: EngagementReportRawData) => obj?.numberVisits && obj?.numberVisits > 0)
      .length /
      reports.length) *
      100
  );
  const usersChurn = round((usersDischarged / usersInThisReport) * 100, 2);

  return {
    usersOnboarded,
    usersCreated,
    usersActive,
    usersDischarged,
    usersOnHold,
    usersInThisReport,
    usersCriteriaMet,
    bandsInSystem,
    bandActivations,
    replacementActivations,
    appEngagement,
    usersChurn,
  };
};
