import { createApi } from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash/isEmpty';

import { Activities, ActivityDay } from 'models/activities';
import { apiBaseQuery } from 'services/base';

export interface QueryParams {
  userId: string;
  startDate: string;
  endDate: string;
}

export const activitiesApi = createApi({
  reducerPath: 'activitiesApi',
  baseQuery: apiBaseQuery('/activities/api/v1'),
  tagTypes: ['Activities'],
  endpoints(builder) {
    return {
      fetchPatientActivitiesCompliance: builder.query<ActivityDay[], QueryParams>({
        query: (queryParams) => ({
          url:
            `/activities/${queryParams.userId}/?from=${queryParams?.startDate}&to=${queryParams?.endDate}` +
            '&activityTypes=LOGGING_MEDICATION,LOGGING_FOOD&size=28',
          method: 'GET',
        }),
        providesTags: ['Activities'],
        transformResponse: (data: Activities) => {
          return data.content.filter((item: ActivityDay) => {
            return !isEmpty(item.activities);
          });
        },
      }),
    };
  },
});

export const {
  useFetchPatientActivitiesComplianceQuery,
  useLazyFetchPatientActivitiesComplianceQuery,
} = activitiesApi;
