import { BillingCareMemberRawData, EngagementReportRawData } from 'models/reports';
import { AccountStatus } from 'models/user';

export const getReportMemberStatus = (
  data: BillingCareMemberRawData | EngagementReportRawData
): AccountStatus | null => {
  if (data.isInTheTenant !== null) {
    if (data.isInTheTenant === false) {
      return 'DISCHARGED';
    }
    if (data.isOnHold === true) {
      return 'HOLDING';
    }

    if (data.isInTheTenant === true) {
      if (data.memberOnboardedTimestamp) {
        return 'ACTIVE';
      }
      return 'CREATED';
    }
  }

  return null;
};
