import { useCallback } from 'react';

import { useCreateAnalyticsEventMutation } from 'services/analytics';
import { useAppSelector } from 'store/index';
import { selectedTenant, sessionId, tokenParsed } from 'store/auth';

export function useUserAnalytics() {
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const sessionIdSelector = useAppSelector(sessionId);
  const tenant = useAppSelector(selectedTenant);

  const [createEvent] = useCreateAnalyticsEventMutation();

  const onCreateEvent = useCallback(
    (event: any, component: string, parent: string) => {
      const userAnalyticsPayload = {
        metadata: {
          eventType: 'jsonObject',
          userId: tokenParsedSelector?.sub,
          tenantId: tenant?.tenant_ID,
          sessionId: sessionIdSelector,
          timestamp: new Date().toISOString(),
          client: 'webApp',
          clientVersion: '0.1.0',
          userAgent: navigator.userAgent,
          operatingSystem: navigator.appVersion,
        },
        event: {
          componentName: component,
          parentComponent: parent,
          type: event._reactName,
        },
      };
      createEvent(userAnalyticsPayload);
    },
    [tokenParsedSelector, tenant, createEvent, sessionIdSelector]
  );

  return { onCreateEvent };
}
