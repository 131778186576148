import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { KeycloakTokenParsed } from 'keycloak-js';
import { RootState } from 'store/rootReducer';
import { Tenant } from 'models/user';
import { loadState } from 'utils/localStorage';

export interface InitialAuthStateProps {
  token: string;
  tenant: Tenant;
  authenticated: boolean;
  tokenParsed: KeycloakTokenParsed | undefined;
  sessionId: string | undefined;
}

const initialState: InitialAuthStateProps = {
  token: loadState('token', 'STRING'),
  tenant: loadState('tenant'),
  authenticated: false,
  tokenParsed: undefined,
  sessionId: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    setTenant: (state, action: PayloadAction<Tenant>) => {
      return {
        ...state,
        tenant: action.payload,
      };
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        authenticated: action.payload,
      };
    },
    setTokenParsed: (state, action: PayloadAction<KeycloakTokenParsed>) => {
      return {
        ...state,
        tokenParsed: action.payload,
      };
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        sessionId: action.payload,
      };
    },
  },
});

export const { setTenant, setToken, setAuthenticated, setTokenParsed, setSessionId } =
  authSlice.actions;
export const currentToken = (state: RootState) => state.auth.token;
export const selectedTenant = (state: RootState) => state.auth.tenant;
export const authenticated = (state: RootState) => state.auth.authenticated;
export const tokenParsed = (state: RootState) => state.auth.tokenParsed;
export const sessionId = (state: RootState) => state.auth.sessionId;
export const selectIsAdmin = (state: RootState) => state.auth.tenant.roles.includes('tenant_admin');
export const selectIsClinician = (state: RootState) =>
  !isEmpty(state.auth.tenant.roles) &&
  state.auth.tenant.roles.some((role) => role !== 'tenant_admin' && role !== 'patient');
export const selectIsPatient = (state: RootState) => state.auth.tenant.roles.includes('patient');
export const selectRoles = (state: RootState) => state.auth.tenant.roles;

export default authSlice.reducer;
