import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getKeycloakInitOptions, tokenLogger, keycloak } from './keycloak';
import { FeatureFlagProvider } from 'hooks/featureFlag';
import featureFlags from '@constants/featureFlags';

import AuthenticationChecker from './AuthenticationChecker';
import { SbCallsProvider } from 'hooks/sbCalls';
import reportWebVitals from './reportWebVitals';
import store from 'store/index';
import AppThemeProvider from 'styles/AppThemeProvider';
import CircularProgressComponent from 'components/progressIndicators/CircularProgressComponent';
import SnackbarProvider from 'components/SnackbarProvider';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppThemeProvider>
        <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={getKeycloakInitOptions()}
          autoRefreshToken={true}
          onTokens={tokenLogger}
          LoadingComponent={<CircularProgressComponent />}
        >
          <SbCallsProvider appId="">
            <FeatureFlagProvider features={featureFlags}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline />
                <SnackbarProvider>
                  <AuthenticationChecker />
                </SnackbarProvider>
              </LocalizationProvider>
            </FeatureFlagProvider>
          </SbCallsProvider>
        </ReactKeycloakProvider>
      </AppThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
