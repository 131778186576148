import { createApi } from '@reduxjs/toolkit/query/react';
import { format, getUnixTime } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { lbsToKg, ftInToCm, kgToLbs, cmToFtIn } from 'utils/conversions';
import { apiBaseQuery, RadiusApiError } from 'services/base';
import { CreateUserRequestPayload, RealmType } from 'models/admin';
import {
  AccountInvitationModel,
  isMemberInvitationModel,
  isStaffInvitationModel,
  MemberInvitationModel,
} from 'models/accountInvitation';
import { SystemSurvey, SystemSurveyAnswers } from 'models/systemSurvey';
import { AccountImport } from 'models/accountImport';
import { formatDobFromCSV } from 'utils/admin';
import { GENDER_LABEL, SEX_LABEL } from '@constants/admin';
import { systemSurveyFromSystemSurveyData, SystemSurveyData } from './surveyModelMapping';
import type { RootState } from 'store/rootReducer';
import { setSubmissionProgress } from 'store/admin/accountImport';
import { setIntakeSurveyAnswers } from 'store/admin/memberInvitation';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { User } from 'models/user';

interface CreateUserMutationParams {
  invitation: AccountInvitationModel;
  tenantId: string;
  tenantName: string;
}

interface SubmitIntakeSurveyMutationParams {
  answers: { [key: string]: SystemSurveyAnswers };
  userId: string;
  tenantId: string;
}

interface CreateMultiplePatientsParams {
  members: AccountImport[];
  realm: RealmType;
  tenantId: string;
  tenantName: string;
}

interface SendSmsMutationParams {
  userId: string;
  tenantId: string;
}

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: apiBaseQuery('/user_admin/api/v1'),
  tagTypes: ['AccountInvitation'],
  endpoints(builder) {
    return {
      createUser: builder.mutation<string, CreateUserMutationParams>({
        query: (params) => {
          const realm = isMemberInvitationModel(params.invitation) ? 'patient' : 'care';
          return {
            url: `/realms/${realm}/tenants/${params.tenantId}/users`,
            method: 'post',
            data: userDataFromAccountInvitationModel(params.invitation, params.tenantName),
            responseHandler: (response: Response) => response.text(),
          };
        },
        invalidatesTags: ['AccountInvitation'],
      }),

      sendSmsToMember: builder.mutation<string, SendSmsMutationParams>({
        query: (params) => ({
          url: `/realms/patient/tenants/${params.tenantId}/users/${params.userId}/resend`,
          method: 'POST',
        }),
      }),

      getIntakeSurveys: builder.query<SystemSurvey[], void>({
        async queryFn(_params, queryApi, extraOptions, _baseQuery) {
          const surveysResponse = (await apiBaseQuery('/factuary/api/')(
            {
              url: `v1/surveys/?intake=true`,
            },
            queryApi,
            extraOptions
          )) as QueryReturnValue<{ surveys: SystemSurveyData[] }, RadiusApiError>;

          if (surveysResponse.error) {
            return surveysResponse;
          }

          const intakeSurveyAnswers = surveysResponse.data.surveys.reduce((answers, survey) => {
            return {
              ...answers,
              [survey.id]: {},
            };
          }, {});

          queryApi.dispatch(setIntakeSurveyAnswers(intakeSurveyAnswers));

          return {
            data: surveysResponse.data.surveys.map((survey) =>
              systemSurveyFromSystemSurveyData(survey)
            ),
          };
        },
      }),

      submitIntakeSurvey: builder.mutation<unknown, SubmitIntakeSurveyMutationParams>({
        async queryFn(params, queryApi, extraOptions, _baseQuery) {
          const {
            admin: {
              accountActions: { intakeSurveys },
            },
          } = queryApi.getState() as RootState;

          const tenantIdBySurvey: { [key: string]: string } = intakeSurveys.reduce(
            (prev: { [key: string]: string }, intakeSurvey: SystemSurvey) => {
              return {
                ...prev,
                [intakeSurvey.id]: intakeSurvey.tenantId,
              };
            },
            {}
          );

          const surveysAnswered = Object.entries(params.answers).filter(
            ([, answers]) => !isEmpty(answers)
          );

          const responseAnswers = await Promise.all(
            surveysAnswered.map(([surveyId, answers]) =>
              apiBaseQuery('/factuary/api/')(
                {
                  url: `v1/surveys/${surveyId}/answers/`,
                  method: 'post',
                  data: {
                    answers,
                    time_answered: getUnixTime(new Date()),
                    user_id: params.userId,
                    tenant_id: tenantIdBySurvey[surveyId],
                    as_ctm: true,
                  },
                },
                queryApi,
                extraOptions
              )
            )
          );

          const errors = responseAnswers
            .filter((response) => response.error)
            .map((response) => response.error);

          if (errors.length) {
            return { error: { status: undefined, data: errors } };
          }

          return {
            data: responseAnswers
              .filter((response) => response.data)
              .map((response) => response.data),
          };
        },
      }),

      createMultipleMembers: builder.mutation<
        { successMembers: string[]; errorMembers: string[] },
        CreateMultiplePatientsParams
      >({
        async queryFn(queryParams, { dispatch }, extraOptions, baseQuery) {
          const members = queryParams.members;
          const successMembers: string[] = [];
          const errorMembers: string[] = [];

          const requestCount = members.length;
          let progressPerRequest = 0;
          let currentProgress = 0;

          dispatch(setSubmissionProgress(currentProgress));

          for (const member of members) {
            const payload = parseImportedAccount(member, queryParams.tenantName);
            const response = await baseQuery({
              url: `/realms/${queryParams.realm}/tenants/${queryParams.tenantId}/users`,
              method: 'POST',
              data: { ...payload },
            });

            if (response.error) {
              errorMembers.push(member.contact);
            } else {
              successMembers.push(member.contact);
            }
            currentProgress += 1;
            progressPerRequest = (currentProgress / requestCount) * 100;
            await dispatch(setSubmissionProgress(progressPerRequest));
          }

          return { data: { successMembers, errorMembers } };
        },
      }),
    };
  },
});

export const {
  useCreateUserMutation,
  useSendSmsToMemberMutation,
  useGetIntakeSurveysQuery,
  useSubmitIntakeSurveyMutation,
  useCreateMultipleMembersMutation,
} = adminApi;

function userDataFromAccountInvitationModel(
  invitation: AccountInvitationModel,
  tenantName: string
): CreateUserRequestPayload {
  return {
    tenantName,
    roles: serverRolesFromAccountInvitationModel(invitation),
    email: invitation.email.trim(),
    phoneNumber: invitation.phone.trim(),
    title: null,
    givenName: invitation.firstName.trim(),
    familyName: invitation.lastName.trim(),
    middleNames: null,
    dateOfBirth: invitation.dateOfBirth ? format(invitation.dateOfBirth, 'yyyy-MM-dd') : null,
    gender: invitation.gender,
    sex: invitation.sex,
    height: isMemberInvitationModel(invitation)
      ? heightInMetersFromHeightInUnits(invitation.height)
      : null,
    heightDisplayUnits: isMemberInvitationModel(invitation)
      ? serverHeightUnitsFromClientHeightUnits(invitation.heightDisplayUnits)
      : ['FOOT', 'INCH'],
    weight: isMemberInvitationModel(invitation)
      ? weightInKilogramsFromWeightInUnits(invitation.weight, invitation.weightDisplayUnits)
      : null,
    weightDisplayUnits: isMemberInvitationModel(invitation)
      ? serverWeightUnitsFromClientWeightUnits(invitation.weightDisplayUnits)
      : ['POUND'],
    streetAddress: invitation.street ? invitation.street.trim() : null,
    city: invitation.city ? invitation.city.trim() : null,
    state: invitation.stateOrRegion,
    zip: invitation.zip ? invitation.zip.trim() : null,
    countryCode: invitation.country?.code ?? null,
    countryName: invitation.country?.name ?? null,
    password: null,
    qhcp: isStaffInvitationModel(invitation) ? invitation.qhcp : false,
    triggerInvitation: isMemberInvitationModel(invitation),
    orderSource: 'ADMIN_PORTAL',
  };
}

function serverRolesFromAccountInvitationModel(invitation: AccountInvitationModel): string[] {
  if (isMemberInvitationModel(invitation)) {
    return ['patient'];
  }

  if (invitation.isAdmin) {
    return ['tenant_admin', invitation.role].filter(Boolean) as string[];
  }

  if (!invitation.role) {
    throw new Error('Invalid account invitation: "role" is required for clinicians');
  }

  return [invitation.role];
}

function serverHeightUnitsFromClientHeightUnits(
  clientUnits: MemberInvitationModel['heightDisplayUnits']
) {
  return clientUnits === 'ft/in' ? ['FOOT', 'INCH'] : ['CENTIMETER'];
}

function clientHeightUnitsFromServerHeightUnits(
  serverUnits?: string[]
): MemberInvitationModel['heightDisplayUnits'] {
  return Array.isArray(serverUnits) && serverUnits.length === 1 && serverUnits[0] === 'CENTIMETER'
    ? 'cm'
    : 'ft/in';
}

function serverWeightUnitsFromClientWeightUnits(
  clientUnits: MemberInvitationModel['weightDisplayUnits']
) {
  return clientUnits === 'lbs' ? ['POUND'] : ['KILOGRAM'];
}

function clientWeightUnitsFromServerWeightUnits(
  serverUnits?: string[]
): MemberInvitationModel['weightDisplayUnits'] {
  return Array.isArray(serverUnits) && serverUnits.length === 1 && serverUnits[0] === 'KILOGRAM'
    ? 'kg'
    : 'lbs';
}

export function weightInKilogramsFromWeightInUnits(
  value: number | null,
  units: MemberInvitationModel['weightDisplayUnits']
) {
  if (value === null) {
    return null;
  }

  if (units === 'kg') {
    return value;
  }

  return lbsToKg(value);
}

function weightInUnitsFromWeightInKilograms(
  weightInKg: number | undefined,
  desiredUnits: MemberInvitationModel['weightDisplayUnits']
) {
  if (typeof weightInKg !== 'number') {
    return null;
  }
  if (desiredUnits === 'kg') {
    return weightInKg;
  }
  return kgToLbs(weightInKg);
}

export function heightInMetersFromHeightInUnits(value: number | null | Array<number | null>) {
  if (value === null) {
    return null;
  }

  if (typeof value === 'number') {
    // value is in cm
    return value / 100;
  }

  const cm = ftInToCm(value);
  return cm && cm / 100;
}

function heightInUnitsFromHeightInMeters(
  heightInMeters: number | undefined,
  desiredUnits: MemberInvitationModel['heightDisplayUnits']
) {
  if (typeof heightInMeters !== 'number') {
    return null;
  }
  if (desiredUnits === 'cm') {
    return heightInMeters * 100;
  }
  return cmToFtIn(heightInMeters * 100);
}

function parseImportedAccount(user: AccountImport, tenantName: string): CreateUserRequestPayload {
  const phoneNumber = user.phone.trim();
  const sex = user.sex ? SEX_LABEL[user.sex] : null;
  const gender = user.gender ? GENDER_LABEL[user.gender] : null;

  return {
    tenantName,
    roles: [user.role || 'patient'],
    email: user.contact.trim(),
    phoneNumber,
    title: user.title ? user.title.trim() : null,
    givenName: user.firstname,
    familyName: user.lastname,
    middleNames: null,
    dateOfBirth: user.birthdate ? formatDobFromCSV(user.birthdate.trim()) : null,
    gender,
    sex,
    height: user.height || null,
    heightDisplayUnits: ['FOOT', 'INCH'],
    weight: user.weight || null,
    weightDisplayUnits: ['POUND'],
    streetAddress: user.address ? user.address.trim() : null,
    city: user.city ? user.city.trim() : null,
    state: user.state ? user.state.trim() : null,
    zip: user.zip ? user.zip.trim() : null,
    countryCode: user.countrycode || null,
    countryName: user.countryname || null,
    password: null,
    qhcp: ['Yes', 'yes', 'True', 'true'].includes(user.qhcp as string) || false,
    orderSource: 'ADMIN_PORTAL',
  };
}

export function memberHeightAndWeightFromUserRecord(user: User) {
  return {
    height: heightInUnitsFromHeightInMeters(
      user.bioInfo?.heightInMeters,
      clientHeightUnitsFromServerHeightUnits(user.personalInfo.heightDisplayUnits)
    ),
    weight: weightInUnitsFromWeightInKilograms(
      user.bioInfo?.weightInKilograms,
      clientWeightUnitsFromServerWeightUnits(user.personalInfo.weightDisplayUnits)
    ),
    heightDisplayUnits: clientHeightUnitsFromServerHeightUnits(
      user.personalInfo.heightDisplayUnits
    ),
    weightDisplayUnits: clientWeightUnitsFromServerWeightUnits(
      user.personalInfo.weightDisplayUnits
    ),
  };
}
