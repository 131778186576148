import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useAppSelector, useAppDispatch } from 'store/index';
import {
  selectedTenant,
  setAuthenticated,
  setToken,
  setTokenParsed,
  setSessionId,
} from 'store/auth';
import App from './App';
import TenantSelector from 'containers/auth';
import isEmpty from 'lodash/isEmpty';

export default function AuthenticationChecker() {
  const dispatch = useAppDispatch();
  const { initialized, keycloak } = useKeycloak();
  const tenant = useAppSelector(selectedTenant);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (
      !keycloak.authenticated ||
      !keycloak.token ||
      !keycloak.tokenParsed ||
      !keycloak.sessionId
    ) {
      return;
    }

    dispatch(setAuthenticated(true));
    dispatch(setToken(keycloak.token));
    dispatch(setTokenParsed(keycloak.tokenParsed));
    dispatch(setSessionId(keycloak.sessionId));
  }, [
    initialized,
    keycloak.authenticated,
    keycloak.token,
    keycloak.tokenParsed,
    keycloak.sessionId,
    dispatch,
  ]);

  if (!initialized) {
    return null;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  if (!tenant || isEmpty(tenant?.roles)) {
    return <TenantSelector />;
  }

  return <App />;
}
