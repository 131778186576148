import { createApi } from '@reduxjs/toolkit/query/react';

import { apiBaseQuery } from 'services/base';
import { RealmType } from 'models/admin';
import { User } from 'models/user';

interface QueryParams {
  email?: string;
  realm?: RealmType;
  oldPassword?: string;
  newPassword?: string;
}

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: apiBaseQuery('/login/api/v1'),
  tagTypes: ['Login'],
  endpoints(builder) {
    return {
      resetPassword: builder.mutation<User, QueryParams>({
        query: (queryParams) => ({
          url: `/${queryParams.realm}/reset-password`,
          method: 'post',
          data: { email: queryParams.email },
        }),
        invalidatesTags: ['Login'],
      }),
      updatePassword: builder.mutation<User, QueryParams>({
        query: (queryParams) => ({
          url: `/${queryParams.realm}/update-password`,
          method: 'post',
          data: {
            username: queryParams.email,
            oldPassword: queryParams.oldPassword,
            newPassword: queryParams.newPassword,
          },
        }),
        invalidatesTags: ['Login'],
      }),
    };
  },
});

export const { useResetPasswordMutation, useUpdatePasswordMutation } = loginApi;
